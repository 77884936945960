import React, { useState, useEffect } from 'react';
import "./Home.css";
import ReactWebChat, { createDirectLine } from 'botframework-webchat';
import './botstyle.css'; // Adjust the path as per your directory structure
import { createStyleSet } from 'botframework-webchat';
import { CRM_ID as initial_CRM_ID, DIALOG as initial_DIALOG } from './config.js';

function Home() {
    const [directLine, setDirectLine] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // Extract the CRM_ID and DIALOG from query parameters or use defaults
    const queryParams = new URLSearchParams(window.location.search);
    const CRM_ID = queryParams.get('CRM_ID') || initial_CRM_ID;
    let DIALOG = queryParams.get('DIALOG') || initial_DIALOG; // Modified to allow query parameter override


    // style the chat here
    const styleSet = createStyleSet({
        bubbleFromUserBackground: '#0000FF',
        bubbleFromUserTextColor: '#FFFFFF',
        bubbleBorderRadius: 10,
        bubbleFromUserBorderRadius: 10,
    });

    useEffect(() => {
        const fetchToken = async () => {
            setIsLoading(true); // Ensure loading state is true when starting to fetch
            try {
                const response = await fetch('https://healthychatadmin.azurewebsites.net/api/get_chat_token');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const { token } = await response.json();
                if (token) {
                    setDirectLine(createDirectLine({ token }));
                } else {
                    console.error('Token not found in response');
                }
            } catch (error) {
                console.error('Error fetching token:', error);
            } finally {
                setIsLoading(false); // Set loading to false regardless of success or failure
            }
        };

        fetchToken();
    }, []);

    useEffect(() => {
        // Define sendCustomEvent inside useEffect
        const sendCustomEvent = () => {
            // Assuming directLine is already checked for non-null before this function is called
            const message = {
                type: 'event',
                name: 'HC_Portal_Event',
                from: { id: 'YOUR_USER_ID' },
                value: {
                    language: navigator.language,
                    user: "anonymous", // Here you can set a fixed value or parameter
                    crmId: CRM_ID, // Ensure CRM_ID is defined or accessible in this scope
                    dialog: DIALOG // Ensure DIALOG is defined or accessible in this scope
                }
            };
    
            directLine.postActivity(message).subscribe(
                id => console.log("Posted activity, assigned ID ", id),
                error => console.error("Error posting activity", error)
            );
        };
    
        // Now, we call sendCustomEvent only if directLine is available
        if (directLine) {
            sendCustomEvent();
        }
    }, [directLine,CRM_ID,DIALOG]); // directLine is the only dependency now
    

    const toggleRoot = () => {
        const container = document.getElementById("container");
        if (container.style.display === "none") {
            container.style.display = "block";
        } else {
            container.style.display = "none";
        }
    };
    return (
        <div className="overlay">
            <div className="home">
                <div className="top-right-button">
                    <button type="button" className="btn btn-lg subtle-button" onClick={() => toggleRoot()}>
                        <span style={{ fontSize: '24px' }}>X</span>
                    </button>
                </div>
                {isLoading ? (
                    <p>Connecting to chatbot...</p>
                ) : directLine ? (
                    <ReactWebChat directLine={directLine} userID="YOUR_USER_ID" styleSet={styleSet} />
                ) : (
                    <p>Error connecting to chatbot. Please try again later.</p>
                )}
            </div>
        </div>
    );
}

export default Home;
